.sidebar {
  width: 255px;
  /* padding: 20px 0 0 0; */
  height: 100%;
  position: fixed;
  left: 0;
}

.sidebar .logo img {
  padding-inline: 20px;
}

.sidebar .listingWrapper {
  padding-left: 20px;
}

.sidebar nav {
  height: calc(100vh - 220px);
}

.sidebar nav ul {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
}

.sidebar nav ul li {
  padding: 10px 0;
}

.sidebar nav ul li a {
  text-decoration: none;
  display: block;
  padding: 5px 0;
  color: #333;
  font-size: 20px;
  line-height: 30px;
  padding: 10px 20px;
  position: relative;
}

.sidebar .signOut {
  /* width: 100%; */
  padding: 10px;
  color: #64b431;
  background-color: transparent;
  font: 26px;
  list-style: 35px;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  font: 500;
}

.activeNavLink .active {
  background: red !important;
  color: red !important;
}

.hamCross {
  width: 20px;
  height: 20px;
  display: none;
}

.hamBurgerIcon {
  display: none;
}
.presentedByText {
  color: #1d1e2c;
  font-size: 18.33px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
  text-align: center;
  /* padding-top: 30px; */
}

@media only screen and (max-width: 991px) {
  .sidebar {
    transform: translateX(-255px);
    transition: 0.3s all ease-in-out;
  }

  .sidebar.open {
    transform: translateX(-0px);
    transition: 0.3s all ease-in-out;
    z-index: 999;
    background: linear-gradient(180deg, #1d1e2c 0%, #64b431 100%);
  }

  .hamburger {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 40px;
    right: -82px;
    z-index: 999;
  }

  .line {
    width: 24px;
    height: 2px;
    background-color: #000;
    margin: 6px 0;
  }

  .hamBurgerIcon {
    width: 30px;
  }

  .hamBurgerIcon {
    display: block;
  }

  .sidebar.open .hamBurgerIcon {
    display: none;
  }

  .sidebar.open .hamCross {
    display: block;
  }

  .sidebar.open .hamburger {
    right: -27px;
    top: 45px;
  }
  .sideBarFooter {
    background: #fff;
  }
  .sidebar .listingWrapper {
    padding: 20px;
  }
  .sidebar nav {
    height: 60vh;
  }
}
