.userDashboardWrapper {
  background-color: #ebf7e2;
  padding-block: 20px;
  overflow: hidden;
}

.userDashboard {
  background-color: #fafff6;
  padding: 65px 30px 30px 30px;
  border-radius: 12px;
  min-height: calc(100vh - 40px);
  background: url(../../assets/images/BG3.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.leftSide {
  position: relative;
  z-index: 1;
}

.header {
  text-align: center;
  margin-bottom: 30px;
}

.header h1 {
  background: linear-gradient(180deg, #1d1e2c 0%, #64b431 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: clamp(2rem, 4vw, 3rem);
  font-weight: 700;
  line-height: 1.2;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* animation: fadeIn 1.5s ease-in-out; */
}

.formContainer {
  padding: 30px;
  border-radius: 12px;
  background-color: transparent; /* Removed background color */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); /* Only box shadow remains */
  border: none; /* Removed border */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.formContainer:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.label {
  font-weight: 600;
  color: #1d1e2c;
  font-family: "Inter", sans-serif !important;
  font-size: 1.1rem;
  margin-bottom: 8px;
  text-transform: uppercase;
  /* letter-spacing: 0.5px; */
}

.input {
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 10px;
  font-size: 1rem;
  font-family: "Inter", sans-serif !important;
  background-color: transparent; /* Slight transparency for inputs */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.input:focus {
  border-color: #64b431;
  box-shadow: 0 0 8px rgba(100, 180, 49, 0.3);
  outline: none;
  background-color: #ffffff;
}

.submitBtn {
  /* min-width: 130px; */
  /* padding: 12px 24px; */
  /* font-size: 1rem; */
  /* font-weight: 600; */
  /* border-radius: 8px; */
  /* background-color: #64b431; */
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.submitBtn:disabled {
  background-color: #a0d0a0;
  cursor: not-allowed;
}

.submitBtn:hover:not(:disabled) {
  background-color: #5aa32c;
  transform: scale(1.05);
}

/* .animatedButton {
  animation: pulse 1.5s infinite;
} */

/* @keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
} */

.linkSection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background-color: transparent; /* Made transparent */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Only box shadow */
  margin-top: 20px;
  transition: transform 0.3s ease;
}

.linkSection:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.link {
  font-size: 0.9rem;
  color: #007bff;
  transition: color 0.3s ease;
}

.link:hover {
  color: #0056b3;
  text-decoration: none;
}

.copyIcon {
  margin-left: 8px;
  transition: color 0.3s ease;
}

.copyIcon:hover {
  color: #64b431;
}

/* @keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} */
