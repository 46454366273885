.videoFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  /* Radial gradient background */
  background: radial-gradient(circle, #66ff66, #004d00, #ffffff);
}

.logoContainer {
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  max-width: 150px;
  width: 100%;
  height: auto;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: rgba(
    255,
    255,
    255,
    0.9
  ); /* Slightly transparent white background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.formGroup {
  display: flex;
  flex-direction: column;
}
.completionScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4; /* Adjust background color as needed */
  padding: 50px;
  border-radius: 8px;
}
.formWrapper {
  display: block;
  background-color: rgb(255, 255, 255);
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 5px 0px,
    rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;
  padding: 12px 30px 30px;
  align-self: stretch;
  margin-bottom: 1.5rem;
  border-radius: 2px;
  word-break: break-word;
}

.video::-webkit-media-controls-timeline {
  pointer-events: none; /* Disables interaction with the progress bar */
}
.watchedVideo::-webkit-media-controls-timeline {
  pointer-events: all;
  /* display: none !important; */
}
.completionMessage {
  font-size: 20px;
  color: #000;
  text-align: center;
  margin-top: 20px;
}
.jobTitle {
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
  font-family: "Inter", sans-serif !important;
  text-align: center;
  /* margin-bottom: 1rem; */
}

.notes {
  font-size: 14px;
  white-space: pre-wrap;
  font-family: "Inter", sans-serif !important;
  font-weight: 500;
  margin: 0px;
  color: #000;
  text-align: center;
}

.errorMessage {
  color: #d9534f;
  font-size: 14px;
}

.videoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video {
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.proceedButton {
  padding: 10px 20px;
  background-color: #66ff66;
  color: #004d00;
  border: 1px solid #004d00;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.proceedButton.disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.proceedButton:not(.disabled):hover {
  background-color: #004d00;
  color: #ffffff;
}

@media (max-width: 768px) {
  .video-form-container {
    padding: 10px;
  }

  .form {
    padding: 15px;
  }

  .input,
  .submitButton {
    font-size: 14px;
  }
}
